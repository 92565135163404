import { Message } from 'element-ui';

/**
 * 颜色转换函数
 * @method hexToRgb hex 颜色转 rgb 颜色
 * @method rgbToHex rgb 颜色转 Hex 颜色
 * @method getDarkColor 加深颜色值
 * @method getLightColor 变浅颜色值
 */
export function useChangeColor() {
  // str 颜色值字符串
  var hexToRgb = function hexToRgb(str) {
    var hexs = '';
    var reg = /^#?[0-9A-Fa-f]{6}$/;
    if (!reg.test(str)) {
      Message.warning('输入错误的hex');
      return '';
    }
    str = str.replace('#', '');
    hexs = str.match(/../g);
    for (var i = 0; i < 3; i++) hexs[i] = parseInt(hexs[i], 16);
    return hexs;
  };
  // r 代表红色 | g 代表绿色 | b 代表蓝色
  var rgbToHex = function rgbToHex(r, g, b) {
    var reg = /^\d{1,3}$/;
    if (!reg.test(r) || !reg.test(g) || !reg.test(b)) {
      Message.warning('输入错误的rgb颜色值');
      return '';
    }
    var hexs = [r.toString(16), g.toString(16), b.toString(16)];
    for (var i = 0; i < 3; i++) if (hexs[i].length == 1) hexs[i] = "0".concat(hexs[i]);
    return "#".concat(hexs.join(''));
  };
  // color 颜色值字符串 | level 变浅的程度，限0-1之间
  var getDarkColor = function getDarkColor(color, level) {
    var reg = /^#?[0-9A-Fa-f]{6}$/;
    if (!reg.test(color)) {
      Message.warning('输入错误的hex颜色值');
      return '';
    }
    var rgb = useChangeColor().hexToRgb(color);
    for (var i = 0; i < 3; i++) rgb[i] = Math.floor(rgb[i] * (1 - level));
    return useChangeColor().rgbToHex(rgb[0], rgb[1], rgb[2]);
  };
  // color 颜色值字符串 | level 加深的程度，限0-1之间
  var getLightColor = function getLightColor(color, level) {
    var reg = /^#?[0-9A-Fa-f]{6}$/;
    if (!reg.test(color)) {
      Message.warning('输入错误的hex颜色值');
      return '';
    }
    var rgb = useChangeColor().hexToRgb(color);
    for (var i = 0; i < 3; i++) rgb[i] = Math.floor((255 - rgb[i]) * level + rgb[i]);
    return useChangeColor().rgbToHex(rgb[0], rgb[1], rgb[2]);
  };
  return {
    hexToRgb: hexToRgb,
    rgbToHex: rgbToHex,
    getDarkColor: getDarkColor,
    getLightColor: getLightColor
  };
}