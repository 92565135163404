var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "never", bordered: false } },
    [
      _c("div", { staticClass: "acea-row row-middle" }, [
        _c(
          "div",
          { staticClass: "font-sm after-line", on: { click: _vm.goBack } },
          [
            _c("span", { staticClass: "el-icon-arrow-left" }),
            _vm._v(" "),
            _c("span", { staticClass: "pl10" }, [_vm._v("返回")]),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "ht_title ml10" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }