var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.headMenuNoShow
    ? _c("Mains")
    : _vm.getThemeConfig.layout === "classic"
    ? _c("Classic")
    : _vm.getThemeConfig.layout === "defaults"
    ? _c("Defaults")
    : _vm.getThemeConfig.layout === "transverse"
    ? _c("Transverse")
    : _vm.getThemeConfig.layout === "columns"
    ? _c("Columns")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }