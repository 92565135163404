var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-breadcrumb-seting" },
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "主题编辑",
            visible: _vm.getThemeConfig.isDrawer,
            direction: "rtl",
            "destroy-on-close": "",
            size: "320px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.getThemeConfig, "isDrawer", $event)
            },
            close: _vm.onDrawerClose,
          },
        },
        [
          _c(
            "el-scrollbar",
            { staticClass: "layout-breadcrumb-seting-bar el-main" },
            [
              _c(
                "el-divider",
                { attrs: { "content-position": _vm.contentPosotion } },
                [_vm._v("布局切换")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "layout-drawer-content-flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "layout-drawer-content-item",
                    class: {
                      "drawer-layout-active":
                        _vm.getThemeConfig.layout === "defaults",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onSetLayout("defaults")
                      },
                    },
                  },
                  [
                    _c("section", { staticClass: "el-container el-circular" }, [
                      _c("aside", {
                        staticClass: "el-aside w10 mr5",
                        staticStyle: { width: "17px" },
                      }),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticClass: "el-container is-vertical" },
                        [
                          _c("header", {
                            staticClass: "el-header mb5",
                            staticStyle: { height: "10px" },
                          }),
                          _vm._v(" "),
                          _c("main", { staticClass: "el-main" }),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "layout-drawer-content-item",
                    class: {
                      "drawer-layout-active":
                        _vm.getThemeConfig.layout === "columns",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onSetLayout("columns")
                      },
                    },
                  },
                  [
                    _c("section", { staticClass: "el-container el-circular" }, [
                      _c("aside", {
                        staticClass: "el-aside mr5",
                        staticStyle: { width: "10px" },
                      }),
                      _vm._v(" "),
                      _c("aside", {
                        staticClass: "el-aside-dark mr5",
                        staticStyle: { width: "17px" },
                      }),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticClass: "el-container is-vertical" },
                        [
                          _c("header", {
                            staticClass: "el-header mb5",
                            staticStyle: { height: "10px" },
                          }),
                          _vm._v(" "),
                          _c("main", { staticClass: "el-main" }),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "layout-drawer-content-item",
                    class: {
                      "drawer-layout-active":
                        _vm.getThemeConfig.layout === "classic",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onSetLayout("classic")
                      },
                    },
                  },
                  [
                    _c(
                      "section",
                      { staticClass: "el-container is-vertical el-circular" },
                      [
                        _c("header", {
                          staticClass: "el-aside mb5",
                          staticStyle: { height: "10px" },
                        }),
                        _vm._v(" "),
                        _c("section", { staticClass: "el-container" }, [
                          _c("aside", {
                            staticClass: "el-aside-dark mr5",
                            staticStyle: { width: "17px" },
                          }),
                          _vm._v(" "),
                          _c(
                            "section",
                            { staticClass: "el-container is-vertical" },
                            [_c("main", { staticClass: "el-main" })]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "layout-drawer-content-item",
                    class: {
                      "drawer-layout-active":
                        _vm.getThemeConfig.layout === "transverse",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onSetLayout("transverse")
                      },
                    },
                  },
                  [
                    _c(
                      "section",
                      { staticClass: "el-container is-vertical el-circular" },
                      [
                        _c("header", {
                          staticClass: "el-aside mb5",
                          staticStyle: { height: "10px" },
                        }),
                        _vm._v(" "),
                        _c("section", { staticClass: "el-container" }, [
                          _c(
                            "section",
                            { staticClass: "el-container is-vertical" },
                            [_c("main", { staticClass: "el-main" })]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-divider",
                { attrs: { "content-position": _vm.contentPosotion } },
                [_vm._v("界面设置")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mb10" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("主题")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "90px" },
                          attrs: { placeholder: "请选择", size: "mini" },
                          on: { change: _vm.setLocalTheme },
                          model: {
                            value: _vm.getThemeConfig.themeStyle,
                            callback: function ($$v) {
                              _vm.$set(_vm.getThemeConfig, "themeStyle", $$v)
                            },
                            expression: "getThemeConfig.themeStyle",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "蓝黑", value: "theme-1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "蓝白", value: "theme-2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "绿黑", value: "theme-3" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "绿白", value: "theme-4" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "紫黑", value: "theme-5" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "紫白", value: "theme-6" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "红黑", value: "theme-7" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "红白", value: "theme-8" },
                          }),
                          _vm._v(" "),
                          _vm.getThemeConfig.layout === "columns"
                            ? _c("el-option", {
                                attrs: { label: "渐变", value: "theme-9" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.getThemeConfig.layout === "columns" ||
              _vm.getThemeConfig.layout === "defaults"
                ? _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "layout-breadcrumb-seting-bar-flex-label",
                        },
                        [_vm._v("菜单水平折叠")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "layout-breadcrumb-seting-bar-flex-value",
                        },
                        [
                          _c("el-switch", {
                            attrs: { width: 35 },
                            on: { change: _vm.setLocalThemeConfig },
                            model: {
                              value: _vm.getThemeConfig.isCollapse,
                              callback: function ($$v) {
                                _vm.$set(_vm.getThemeConfig, "isCollapse", $$v)
                              },
                              expression: "getThemeConfig.isCollapse",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("菜单手风琴")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: { width: 35 },
                        on: { change: _vm.setLocalThemeConfig },
                        model: {
                          value: _vm.getThemeConfig.isUniqueOpened,
                          callback: function ($$v) {
                            _vm.$set(_vm.getThemeConfig, "isUniqueOpened", $$v)
                          },
                          expression: "getThemeConfig.isUniqueOpened",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("固定 Header")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: { width: 35 },
                        on: { change: _vm.setLocalThemeConfig },
                        model: {
                          value: _vm.getThemeConfig.isFixedHeader,
                          callback: function ($$v) {
                            _vm.$set(_vm.getThemeConfig, "isFixedHeader", $$v)
                          },
                          expression: "getThemeConfig.isFixedHeader",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-divider",
                { attrs: { "content-position": _vm.contentPosotion } },
                [_vm._v("界面显示")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$store.state.themeConfig.themeConfig.layout !==
                        "columns",
                      expression:
                        "$store.state.themeConfig.themeConfig.layout !== 'columns'",
                    },
                  ],
                  staticClass: "layout-breadcrumb-seting-bar-flex",
                },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("侧边栏 Logo")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: { width: 35 },
                        on: { change: _vm.setLocalThemeConfig },
                        model: {
                          value: _vm.getThemeConfig.isShowLogo,
                          callback: function ($$v) {
                            _vm.$set(_vm.getThemeConfig, "isShowLogo", $$v)
                          },
                          expression: "getThemeConfig.isShowLogo",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "layout-breadcrumb-seting-bar-flex mt15",
                  style: {
                    opacity:
                      _vm.getThemeConfig.layout === "classic" ||
                      _vm.getThemeConfig.layout === "transverse"
                        ? 0.5
                        : 1,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("面包屑")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled:
                            _vm.getThemeConfig.layout === "classic" ||
                            _vm.getThemeConfig.layout === "transverse",
                          width: 35,
                        },
                        on: { change: _vm.setLocalThemeConfig },
                        model: {
                          value: _vm.getThemeConfig.isBreadcrumb,
                          callback: function ($$v) {
                            _vm.$set(_vm.getThemeConfig, "isBreadcrumb", $$v)
                          },
                          expression: "getThemeConfig.isBreadcrumb",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("面包屑图标")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: { width: 35 },
                        on: { change: _vm.setLocalThemeConfig },
                        model: {
                          value: _vm.getThemeConfig.isBreadcrumbIcon,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.getThemeConfig,
                              "isBreadcrumbIcon",
                              $$v
                            )
                          },
                          expression: "getThemeConfig.isBreadcrumbIcon",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("历史菜单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: { width: 35 },
                        on: { change: _vm.setLocalThemeConfig },
                        model: {
                          value: _vm.getThemeConfig.isTagsview,
                          callback: function ($$v) {
                            _vm.$set(_vm.getThemeConfig, "isTagsview", $$v)
                          },
                          expression: "getThemeConfig.isTagsview",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("Footer")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: { width: 35 },
                        on: { change: _vm.setLocalThemeConfig },
                        model: {
                          value: _vm.getThemeConfig.isFooter,
                          callback: function ($$v) {
                            _vm.$set(_vm.getThemeConfig, "isFooter", $$v)
                          },
                          expression: "getThemeConfig.isFooter",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("灰色模式")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: { width: 35 },
                        on: {
                          change: function ($event) {
                            return _vm.onAddFilterChange("grayscale")
                          },
                        },
                        model: {
                          value: _vm.getThemeConfig.isGrayscale,
                          callback: function ($$v) {
                            _vm.$set(_vm.getThemeConfig, "isGrayscale", $$v)
                          },
                          expression: "getThemeConfig.isGrayscale",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("色弱模式")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c("el-switch", {
                        attrs: { width: 35 },
                        on: {
                          change: function ($event) {
                            return _vm.onAddFilterChange("invert")
                          },
                        },
                        model: {
                          value: _vm.getThemeConfig.isInvert,
                          callback: function ($$v) {
                            _vm.$set(_vm.getThemeConfig, "isInvert", $$v)
                          },
                          expression: "getThemeConfig.isInvert",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-divider",
                { attrs: { "content-position": _vm.contentPosotion } },
                [_vm._v("其它设置")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("历史菜单风格")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            disabled: !_vm.getThemeConfig.isTagsview,
                            size: "mini",
                          },
                          on: { change: _vm.setLocalThemeConfig },
                          model: {
                            value: _vm.getThemeConfig.tagsStyle,
                            callback: function ($$v) {
                              _vm.$set(_vm.getThemeConfig, "tagsStyle", $$v)
                            },
                            expression: "getThemeConfig.tagsStyle",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "tags-style-one" } },
                            [_vm._v("卡片")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "tags-style-four" } },
                            [_vm._v("灵动")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "tags-style-five" } },
                            [_vm._v("圆滑")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-breadcrumb-seting-bar-flex mt15" },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("主页面切换动画")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { input: _vm.setLocalThemeConfig },
                          model: {
                            value: _vm.getThemeConfig.animation,
                            callback: function ($$v) {
                              _vm.$set(_vm.getThemeConfig, "animation", $$v)
                            },
                            expression: "getThemeConfig.animation",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "slide-left" } },
                            [_vm._v("左滑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "opacitys" } },
                            [_vm._v("透明")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "slide-right" } },
                            [_vm._v("右滑")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "layout-breadcrumb-seting-bar-flex mt15",
                  class: {
                    mb28:
                      _vm.getThemeConfig.layout !== "columns" &&
                      _vm.getThemeConfig.layout !== "classic",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-label" },
                    [_vm._v("菜单高亮风格")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "layout-breadcrumb-seting-bar-flex-value" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { input: _vm.setLocalThemeConfig },
                          model: {
                            value: _vm.getThemeConfig.columnsAsideStyle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.getThemeConfig,
                                "columnsAsideStyle",
                                $$v
                              )
                            },
                            expression: "getThemeConfig.columnsAsideStyle",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "columns-round" } },
                            [_vm._v("圆角")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "columns-card" } },
                            [_vm._v("卡片")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.getThemeConfig.layout === "columns" ||
              _vm.getThemeConfig.layout === "classic"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "layout-breadcrumb-seting-bar-flex mt15 mb28",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "layout-breadcrumb-seting-bar-flex-label",
                        },
                        [_vm._v("顶级菜单风格")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "layout-breadcrumb-seting-bar-flex-value",
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              on: { input: _vm.setLocalThemeConfig },
                              model: {
                                value: _vm.getThemeConfig.columnsAsideLayout,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.getThemeConfig,
                                    "columnsAsideLayout",
                                    $$v
                                  )
                                },
                                expression: "getThemeConfig.columnsAsideLayout",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "columns-horizontal" } },
                                [_vm._v("水平")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "columns-vertical" } },
                                [_vm._v("垂直")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }