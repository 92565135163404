function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/**
 * 系统内置方法集，正常情况下您不应该修改或移除此文件
 * */

import { cloneDeep } from 'lodash';

/**
 * @description 根据当前路由，找打顶部菜单名称
 * @param {String} currentPath 当前路径
 * @param {Array} menuList 所有路径
 * */
function getHeaderName(to, menuList) {
  var allMenus = [];
  menuList.forEach(function (menu) {
    var headerName = menu.path || '';
    var menus = transferMenu(menu, headerName);
    allMenus.push({
      path: menu.path,
      header: headerName
    });
    menus.forEach(function (item) {
      return allMenus.push(item);
    });
  });
  var currentMenu = allMenus.find(function (item) {
    var path = to.meta && to.meta.activeMenu ? to.meta.activeMenu : to.path;
    if (item.path === path) {
      return true;
    } else {
      return path === getPath(to, item.path);
    }
  });
  return currentMenu ? currentMenu.header : null;
}
function getPath(to, path) {
  var params = [];
  var query = [];
  Object.keys(to.params).forEach(function (item) {
    params.push(to.params[item]);
  });
  Object.keys(to.query).forEach(function (item) {
    query.push(item + '=' + to.query[item]);
  });
  return path + (params.length ? '/' + params.join('/') : '') + (query.length ? '?' + query.join('&') : '');
}
function transferMenu(menu, headerName) {
  if (menu.children && menu.children.length) {
    return menu.children.reduce(function (all, item) {
      all.push({
        path: item.path,
        header: headerName
      });
      var foundChildren = transferMenu(item, headerName);
      return all.concat(foundChildren);
    }, []);
  } else {
    return [menu];
  }
}
export { getHeaderName };

/**
 * @description 根据当前路由，找打顶部菜单名称
 * @param {String} currentPath 当前路径
 * @param {Array} menuList 所有路径
 * */
function getHeaderSider(menuList) {
  return menuList.filter(function (item) {
    return item.pid === 0;
  });
}
export { getHeaderSider };
/**
 * @description 根据当前路由，找以及菜单名称
 * @param {String} currentPath 当前路径
 * @param {Array} menuList 所有路径
 * */
function getOneHeaderName(menuList, path) {
  return menuList.filter(function (item) {
    return item.path === path;
  });
}
export { getOneHeaderName };

/**
 * @description 根据当前顶栏菜单 name，找到对应的二级菜单
 * @param {Array} menuList 所有的二级菜单
 * @param {String} headerName 当前顶栏菜单的 name
 * */
function getMenuSider(menuList) {
  var headerName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (headerName) {
    return menuList.filter(function (item) {
      return item.path === headerName;
    });
  } else {
    return menuList;
  }
}
export { getMenuSider };

/**
 * @description 根据当前路由，找到其所有父菜单 path，作为展开侧边栏 open-names 依据
 * @param {String} currentPath 当前路径
 * @param {Array} menuList 所有路径
 * */
// function getSiderSubmenu (currentPath, menuList) {
//     const allMenus = [];
//     menuList.forEach(menu => {
//         const menus = transferSubMenu(menu, []);
//         allMenus.push({
//             path: menu.path,
//             openNames: []
//         });
//         menus.forEach(item => allMenus.push(item));
//     });
//     const currentMenu = allMenus.find(item => item.path === currentPath);
//     return currentMenu ? currentMenu.openNames : [];
// }

function getSiderSubmenu(to, menuList) {
  var allMenus = [];
  menuList.forEach(function (menu) {
    var menus = transferSubMenu(menu, []);
    allMenus.push({
      path: menu.path,
      openNames: []
    });
    menus.forEach(function (item) {
      return allMenus.push(item);
    });
  });
  var currentMenu = allMenus.find(function (item) {
    if (item.openNames.length) {
      return item.path === to.path || to.path === getPath(to, item.path);
    }
  });
  return currentMenu ? currentMenu.openNames : [];
}
function transferSubMenu(menu, openNames) {
  if (menu.children && menu.children.length) {
    var itemOpenNames = openNames.concat([menu.path]);
    return menu.children.reduce(function (all, item) {
      all.push({
        path: item.path,
        openNames: itemOpenNames
      });
      var foundChildren = transferSubMenu(item, itemOpenNames);
      return all.concat(foundChildren);
    }, []);
  } else {
    return [menu].map(function (item) {
      return {
        path: item.path,
        openNames: openNames
      };
    });
  }
}
export { getSiderSubmenu };

/**
 * @description 递归获取所有子菜单
 * */
function getAllSiderMenu(menuList) {
  var allMenus = [];
  menuList.forEach(function (menu) {
    if (menu.children && menu.children.length) {
      var menus = getMenuChildren(menu);
      menus.forEach(function (item) {
        return allMenus.push(item);
      });
    } else {
      allMenus.push(menu);
    }
  });
  return allMenus;
}
function getMenuChildren(menu) {
  if (menu.children && menu.children.length) {
    return menu.children.reduce(function (all, item) {
      var foundChildren = getMenuChildren(item);
      return all.concat(foundChildren);
    }, []);
  } else {
    return [menu];
  }
}
export { getAllSiderMenu };

/**
 * @description 将菜单转为平级
 * */
function flattenSiderMenu(menuList, newList) {
  menuList.forEach(function (menu) {
    var newMenu = {};
    for (var i in menu) {
      if (i !== 'children') newMenu[i] = cloneDeep(menu[i]);
    }
    newList.push(newMenu);
    menu.children && flattenSiderMenu(menu.children, newList);
  });
  return newList;
}
export { flattenSiderMenu };
var _findFirstNonNullChildren = function findFirstNonNullChildren(arr) {
  // 如果数组为空，返回null
  if (!arr || arr.length === 0) {
    return null;
  }
  // 找到第一个对象
  var firstObj = arr[0];
  // 如果第一个对象没有children属性，返回该对象
  if (!firstObj.children.length) {
    return firstObj;
  }

  // 如果第一个对象的children属性是数组，
  // 递归查找children属性中的第一个非null children属性
  if (firstObj.children.length && Array.isArray(firstObj.children)) {
    return _findFirstNonNullChildren(firstObj.children);
  }
  // 如果数组中没有非null children属性，返回null
  return null;
};
export { _findFirstNonNullChildren as findFirstNonNullChildren };
var _findFirstNonNullChildrenKeys = function findFirstNonNullChildrenKeys(obj, lastArr) {
  var ids = lastArr;
  // 如果第一个对象没有children属性，返回该对象
  if (!obj.children.length) {
    ids.push(obj.id);
    return ids;
  }
  // 如果第一个对象的children属性是数组，
  // 递归查找children属性中的第一个非null children属性
  if (Array.isArray(obj.children) && firstObj.children.length) {
    ids.push(obj.id);
    return _findFirstNonNullChildrenKeys(obj.children[0], ids);
  }
  return ids;
};

// 多级嵌套数组处理成一维数组
export { _findFirstNonNullChildrenKeys as findFirstNonNullChildrenKeys };
export var formatFlatteningRoutes = function formatFlatteningRoutes(arr) {
  if (arr.length <= 0) return false;
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].children && arr[i].children.length) {
      arr = arr.slice(0, i + 1).concat(arr[i].children, arr.slice(i + 1));
    }
  }
  return arr;
};

/**
 * @description 判断列表1中是否包含了列表2中的某一项
 * 因为用户权限 access 为数组，includes 方法无法直接得出结论
 * */
function includeArray(list1, list2) {
  var status = false;
  if (list1 === true) {
    return true;
  } else {
    if (_typeof(list2) !== 'object') {
      return false;
    }
    list2.forEach(function (item) {
      if (list1.includes(item)) status = true;
    });
    return status;
  }
}
export { includeArray };