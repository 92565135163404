var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          staticClass: "list-tabs",
          on: { "tab-click": _vm.radioChange },
          model: {
            value: _vm.typeDate,
            callback: function ($$v) {
              _vm.typeDate = $$v
            },
            expression: "typeDate",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "图片", name: "pic" } }),
          _vm._v(" "),
          _vm.modelName != "diy"
            ? _c("el-tab-pane", { attrs: { label: "视频", name: "video" } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingPic,
              expression: "loadingPic",
            },
          ],
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            _vm._b(
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["platform:category:list:tree"],
                    expression: "['platform:category:list:tree']",
                  },
                ],
                style: { width: !_vm.pictureType ? "23%" : "17%" },
              },
              "el-col",
              _vm.grid,
              false
            ),
            [
              _c("div", { staticClass: "Nav" }, [
                _c("div", { staticClass: "trees-coadd" }, [
                  _c("div", { staticClass: "scollhide" }, [
                    _c(
                      "div",
                      {
                        staticClass: "trees",
                        style: { maxHeight: !_vm.pictureType ? "500px" : "" },
                      },
                      [
                        _c("el-tree", {
                          ref: "tree",
                          attrs: {
                            data: _vm.treeData2,
                            "filter-node-method": _vm.filterNode,
                            props: _vm.defaultProps,
                            "highlight-current": "",
                            "icon-class": "el-icon-arrow-down",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c(
                                  "div",
                                  {
                                    staticClass: "custom-tree-node",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleNodeClick(data)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "custom-tree-node-label",
                                          attrs: { title: node.label },
                                        },
                                        [_vm._v(_vm._s(node.label))]
                                      ),
                                      _vm._v(" "),
                                      data.space_property_name
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "11px",
                                                color: "#3889b1",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "（" + _vm._s(data.name) + "）"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "el-ic" },
                                      [
                                        _c(
                                          "el-dropdown",
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                              },
                                              [
                                                _c("i", {
                                                  class:
                                                    node.label !== "全部图片"
                                                      ? "el-icon-more"
                                                      : "el-icon-plus",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.allAdd(
                                                        node.label
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                node.label !== "全部图片" &&
                                                _vm.checkPermi([
                                                  "platform:category:save",
                                                ])
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onAdd(
                                                              data.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("添加分类")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                node.label !== "全部图片" &&
                                                _vm.checkPermi([
                                                  "platform:category:update",
                                                ])
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onEdit(
                                                              data.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("编辑分类")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                node.label !== "全部图片" &&
                                                _vm.checkPermi([
                                                  "platform:category:delete",
                                                ])
                                                  ? _c(
                                                      "el-dropdown-item",
                                                      {
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleOpenDelete(
                                                              data.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除分类")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            _vm._b(
              {
                staticClass: "colLeft",
                style: { width: !_vm.pictureType ? "77%" : "83%" },
              },
              "el-col",
              _vm.grid2,
              false
            ),
            [
              _c("div", { staticClass: "conter mb15 relative" }, [
                _c(
                  "div",
                  {
                    staticClass: "bnt",
                    style: { paddingLeft: _vm.listType == 1 ? "17px" : "0" },
                  },
                  [
                    !_vm.pictureType
                      ? _c(
                          "el-button",
                          {
                            staticClass: "mr10 mb20",
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.checkPics },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.typeDate === "pic"
                                    ? "使用选中图片"
                                    : "使用选中视频"
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "acea-row" },
                      [
                        _vm.typeDate === "pic" &&
                        _vm.checkPermi([
                          "platform:upload:image",
                          "platform:upload:file",
                        ])
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleChangeImage },
                                  },
                                  [_vm._v("上传图片 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.typeDate !== "pic" &&
                        _vm.checkPermi([
                          "platform:upload:image",
                          "platform:upload:file",
                        ])
                          ? _c(
                              "el-upload",
                              {
                                staticClass: "upload-demo",
                                attrs: {
                                  action: "",
                                  "http-request": _vm.handleUploadForm,
                                  "on-change": _vm.imgSaveToUrl,
                                  headers: _vm.myHeaders,
                                  "show-file-list": false,
                                  multiple: "",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "primary" },
                                  },
                                  [_vm._v("上传视频")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:attachment:delete"],
                                expression: "['platform:attachment:delete']",
                              },
                            ],
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.editPicList("图片")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.typeDate === "pic"
                                      ? "删除图片"
                                      : "删除视频"
                                  ) + "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.pictureType
                      ? _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:attachment:move"],
                                expression: "['platform:attachment:move']",
                              },
                            ],
                            staticClass: "mb20",
                            attrs: {
                              placeholder:
                                _vm.typeDate === "pic"
                                  ? "图片移动至"
                                  : "视频移动至",
                              size: _vm.pictureType ? "" : "small",
                            },
                            model: {
                              value: _vm.sleOptions.attachment_category_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sleOptions,
                                  "attachment_category_name",
                                  $$v
                                )
                              },
                              expression: "sleOptions.attachment_category_name",
                            },
                          },
                          [
                            _c(
                              "el-option",
                              {
                                staticClass: "demo",
                                staticStyle: {
                                  "max-width": "560px",
                                  height: "200px",
                                  overflow: "auto",
                                  "background-color": "#fff",
                                },
                                attrs: {
                                  label:
                                    _vm.sleOptions.attachment_category_name,
                                  value: _vm.sleOptions.attachment_category_id,
                                },
                              },
                              [
                                _c("el-tree", {
                                  ref: "tree2",
                                  attrs: {
                                    data: _vm.treeData2,
                                    "filter-node-method": _vm.filterNode,
                                    props: _vm.defaultProps,
                                    "highlight-current": "",
                                  },
                                  on: { "node-click": _vm.handleSelClick },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "input" }, [
                      _c(
                        "div",
                        [
                          false
                            ? _c("el-input", {
                                staticClass: "searchInput",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder:
                                    _vm.typeDate == "pic"
                                      ? "搜索图片名称"
                                      : "搜索视频名称",
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.filterName,
                                  callback: function ($$v) {
                                    _vm.filterName =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "filterName",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.pictureType
                        ? _c("div", { staticClass: "checkoutBtn" }, [
                            _c(
                              "div",
                              {
                                staticClass: "checkoutBtn-gg",
                                class:
                                  _vm.listType === 1 ? "checkoutActive" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.checkoutBtn(1)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-menu" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "checkoutBtn-list",
                                class:
                                  _vm.listType === 0 ? "checkoutActive" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.checkoutBtn(0)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-s-operation" })]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "abs_video" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "pictrueList acea-row" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowPic,
                          expression: "isShowPic",
                        },
                      ],
                      staticClass: "imagesNo",
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-picture",
                        staticStyle: {
                          "font-size": "60px",
                          color: "rgb(219, 219, 219)",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "imagesNo_sp" }, [
                        _vm._v("素材为空"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.listType === 1,
                          expression: "listType === 1",
                        },
                      ],
                      ref: "myDiv",
                      staticClass: "conters scrollbarAll",
                      style: {
                        maxHeight: !_vm.pictureType ? "500px" : "",
                        display: "flex",
                      },
                    },
                    [
                      _vm._l(_vm.pictrueList.list, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "gridPic",
                            style: _vm.gridPicStyle,
                            on: {
                              mouseenter: function ($event) {
                                return _vm.handleMouse(item, true)
                              },
                              mouseleave: function ($event) {
                                return _vm.handleMouse(item, false)
                              },
                            },
                          },
                          [
                            item.num > 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "num_badge",
                                    style: { right: 0 },
                                  },
                                  [_vm._v(_vm._s(item.num))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.attType !== "video/mp4"
                              ? _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: item.sattDir
                                        ? item.sattDir
                                        : _vm.localImg,
                                      expression:
                                        "item.sattDir ? item.sattDir : localImg",
                                    },
                                  ],
                                  class: item.isSelect ? "on" : "",
                                  style: {
                                    width: _vm.pictureType ? "100%" : "90px",
                                    height: _vm.pictureType ? "146px" : "90px",
                                    "object-fit": "contain",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changImage(
                                        item,
                                        index,
                                        _vm.pictrueList.list
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("img"),
                            _vm._v(" "),
                            item.attType == "video/mp4"
                              ? _c("video", {
                                  class: item.isSelect ? "on" : "",
                                  style: {
                                    width: _vm.pictureType ? "100%" : "90px",
                                    height: _vm.pictureType ? "146px" : "90px",
                                    "object-fit": "contain",
                                  },
                                  attrs: { src: item.sattDir },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changImage(
                                        item,
                                        index,
                                        _vm.pictrueList.list
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "imgTitle",
                                style: {
                                  width: _vm.pictureType
                                    ? (_vm.divWidth - 104) / 6 + "px"
                                    : "90px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.name) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.pictureType && item.isCk
                              ? _c("div", { staticClass: "operate" }, [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteOperate(item)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                  _vm._v(" "),
                                  false
                                    ? _c("span", [_vm._v("重命名")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  false
                                    ? _c("span", [_vm._v("查看")])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "block" },
                        [
                          _c("el-pagination", {
                            attrs: {
                              "page-size": _vm.tableData.limit,
                              "current-page": _vm.tableData.page,
                              "pager-count": 5,
                              layout: "prev, pager, next, jumper",
                              total: _vm.pictrueList.total,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.pageChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.listType === 0,
                          expression: "listType === 0",
                        },
                      ],
                      staticClass: "conters scrollbarAll",
                      style: {
                        maxHeight: !_vm.pictureType ? "500px" : "700px",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isShowPic,
                              expression: "!isShowPic",
                            },
                          ],
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "tooltip-effect": "dark",
                            data: _vm.pictrueList.list,
                          },
                          on: { "selection-change": _vm.listSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "图片名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "imgDiv" }, [
                                      _vm.typeDate == "pic"
                                        ? _c("img", {
                                            staticClass: "listImg",
                                            attrs: {
                                              src: scope.row.sattDir,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.typeDate !== "pic"
                                        ? _c("video", {
                                            staticClass: "listImg",
                                            attrs: { src: scope.row.sattDir },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "imgName" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(scope.row.name) +
                                            "\n                    "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "大小" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [_vm._v(_vm._s(scope.row.attSize))]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "address", label: "上传时间" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [_vm._v(_vm._s(scope.row.createTime))]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteListFun(scope)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                    _vm._v(" "),
                                    false
                                      ? _c(
                                          "span",
                                          { staticClass: "decollator" },
                                          [_vm._v("|")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    false
                                      ? _c(
                                          "el-link",
                                          { attrs: { type: "primary" } },
                                          [_vm._v("查看")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    false
                                      ? _c(
                                          "span",
                                          { staticClass: "decollator" },
                                          [_vm._v("|")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    false
                                      ? _c(
                                          "el-link",
                                          { attrs: { type: "primary" } },
                                          [_vm._v("重命名")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "block" },
                        [
                          _c("el-pagination", {
                            attrs: {
                              "page-size": _vm.tableData.limit,
                              "current-page": _vm.tableData.page,
                              "pager-count": 5,
                              layout: "prev, pager, next, jumper",
                              total: _vm.pictrueList.total,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.pageChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.bizTitle,
            visible: _vm.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            modal: true,
            width: "540px",
            "append-to-body": !_vm.pictureType ? true : false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.closeModel,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "editPram",
              attrs: { model: _vm.editPram, "label-width": "75px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上级分类",
                    prop: "pid",
                    rules: [
                      {
                        type: "number",
                        required: true,
                        message: "请选择上级分类",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: { options: _vm.treeData2, props: _vm.categoryProps },
                    model: {
                      value: _vm.editPram.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "pid", $$v)
                      },
                      expression: "editPram.pid",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分类名称",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请输入分类名称",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分类名称" },
                    model: {
                      value: _vm.editPram.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editPram,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editPram.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.editPram.sort,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editPram,
                          "sort",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editPram.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "rightBtn" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handlerSubmit("editPram")
                        },
                      },
                    },
                    [_vm._v("确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "handleDialog",
          attrs: {
            "custom-class": "uploadDialog",
            title: "上传图片",
            visible: _vm.visiblePic,
            fullscreen: true,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiblePic = $event
            },
            close: function ($event) {
              return _vm.onCallback(false)
            },
          },
        },
        [
          _vm.visiblePic
            ? _c("div", { staticClass: "wrap" }, [
                _c("div", { staticClass: "main" }, [
                  _c("div", { staticClass: "cropperContent" }, [
                    _c("div", { staticClass: "mb35" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("原始图片")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cropper mr20" },
                        [
                          _c("vue-cropper", {
                            ref: "cropper",
                            attrs: {
                              img: _vm.option.img,
                              "output-size": _vm.option.size,
                              "output-type": _vm.option.outputType,
                              info: true,
                              full: _vm.option.full,
                              fixed: _vm.fixed,
                              "fixed-number": _vm.fixedNumber,
                              "can-move": _vm.option.canMove,
                              "can-move-box": _vm.option.canMoveBox,
                              "fixed-box": _vm.option.fixedBox,
                              original: _vm.option.original,
                              "auto-crop": _vm.option.autoCrop,
                              "auto-crop-width": _vm.option.autoCropWidth,
                              "auto-crop-height": _vm.option.autoCropHeight,
                              "center-box": _vm.option.centerBox,
                              high: _vm.option.high,
                              mode: "contain",
                              "max-img-size": _vm.option.max,
                            },
                            on: {
                              "real-time": _vm.realTime,
                              "img-load": _vm.imgLoad,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "scopeButton mt20 mb20" },
                        [
                          _c(
                            "el-button-group",
                            [
                              _c("el-button", { attrs: { type: "primary" } }, [
                                _c("label", { attrs: { for: "uploads" } }, [
                                  _vm._v("选择本地图片"),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "inputFile",
                                  attrs: {
                                    type: "file",
                                    id: "uploads",
                                    accept:
                                      "image/png, image/jpeg, image/gif, image/jpg",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uploadImg($event)
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              !_vm.crap
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.startCrop },
                                    },
                                    [_vm._v("图片裁剪 ")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.stopCrop },
                                    },
                                    [_vm._v("关闭裁剪 ")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.clearCrop },
                                },
                                [_vm._v("清除裁剪 ")]
                              ),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-plus",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeScale(1)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-minus",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeScale(-1)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-refresh-left",
                                },
                                on: { click: _vm.rotateLeft },
                              }),
                              _vm._v(" "),
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-refresh-right",
                                },
                                on: { click: _vm.rotateRight },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "uploadButton acea-row" },
                        [
                          _c(
                            "el-button-group",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-download",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.down("blob")
                                    },
                                  },
                                },
                                [_vm._v("下载 ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-upload2",
                                  },
                                  on: { click: _vm.uploadNewPic },
                                },
                                [_vm._v("上传至图片库 ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: "",
                                    "http-request": _vm.handleUploadForm,
                                    "on-change": _vm.imgSaveToUrl,
                                    headers: _vm.myHeaders,
                                    "show-file-list": false,
                                    multiple: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: {
                                        icon: "el-icon-upload2",
                                        type: "primary",
                                      },
                                    },
                                    [_vm._v("多图上传 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "previewBox" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("实时预览")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "show-preview",
                          style: {
                            width: _vm.previews.w + "px",
                            height: _vm.previews.h + "px",
                            overflow: "hidden",
                            margin: "5px",
                          },
                        },
                        [
                          _c("div", { style: _vm.previews.div }, [
                            _c("img", {
                              style: _vm.previews.img,
                              attrs: { src: _vm.previews.url },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }